/**************************************
 * Theme Name: RAA
 * URL: https://www.raa.com.au
 * Tokiomarine LTD
*****************************************/

@import './bootstrap.scss';
@include bootstrap-raa;
@mixin style-home-raa {
  :root {
    --main-color: #0067f6;
  }
  @font-face {
    font-family: National2Regular;
    src: url(fonts/national2web-regular.woff2);
    font-weight: 400;
  }
  @font-face {
    font-family: National2Bold;
    src: url(fonts/national2web-bold.woff2);
    font-weight: 700;
  }
  ul.navbar-nav li a {
    font-family: National2Regular !important;
  }

  input.form-control.input-number.noborder-0 {
    border: 0px solid #fff !important;
    text-align: center;
  }
  span.input-group-btn glyphicon {
    font-size: 25px;
  }
  button.nav-link.text-dark.ps-md-5.pe-md-5.ps-2.pe-2.active {
    border-bottom: 2px solid #fff !important;
    background: #fff !important;
  }
  .header-top {
    color: #fff;
  }
  img.mainlogo {
    width: 167px;
  }
  img.call-icon {
    width: 26px;
  }
  .header-top span.number a {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
  }
  .header-top span.number a:hover {
    color: #000;
  }
  .header-top span.dayntime {
    color: #fff;
    font-weight: 400;
  }
  img.calender-icon {
    width: 22px;
  }
  img.warringicon {
    width: 17px;
    margin-right: 8px;
    margin-top: -4px;
  }

  .alert.customnotice,
  .container-fluid.notificationss {
    background: #e8e2d3;
    border: none;
    color: #130064;
    margin: 0;
  }

  .alert.customnotice a,
  .container-fluid.notificationss a {
    color: #182d3c !important;
  }
  .navwithbg {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
  }
  section.header.sticky-top.solidheader .navwithbg {
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
    transition: ease all 0.2s;
  }
  .fixed-header-area {
    position: sticky;
    width: 100%;
    top: 0;
    transition: ease all 0.3s;
  }
  //this is fix the sticky header for the home page
  section.header.sticky-top.solidheader {
    position: fixed;
    width: 100%;
    right: 0;
  }
  section.header.fixed-header-area .navwithbg {
    padding: 0.25rem !important;
  }

  .navbar-nav .nav-link {
    color: #001657;
  }
  /* Hover Manu */
  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-link.active {
    color: var(--main-color);
  }

  .navbar-nav .dropdown:hover > .dropdown-menu {
    display: block;
  }

  .customnotice {
    font-size: 12px !important;
  }
  section.mainhero {
    background-image: url(../../images/raa/bg-beach.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: auto;
    margin-top: 150px;
  }
  .herooverlay {
    background: linear-gradient(180deg, #000000, transparent);
    height: 120px;
    position: absolute;
    z-index: 1000;
  }
  .herocontent {
    height: 140px;
    padding-top: 20px;
    z-index: 1001;
    position: relative;
  }
  section.mainhero h1.display-5,
  p.h4.text-light {
    text-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }
  span.specialbtn {
    background: rgb(0 57 136 / 30%) !important;
    color: #fff;
  }
  .customnotice.text-center.rounded-0.rounded-bottom {
    border-bottom-right-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  button.btn-close.filter {
    filter: invert(1);
  }
  .sticky-top.solidheader .borderonscroll {
    border-bottom: 1px solid rgb(0 0 0 / 5%);
    background: #fff;
  }
  .sticky-top.solidheader .header-top span.number a {
    color: #000;
  }
  .sticky-top.solidheader .header-top span.number a:hover {
    color: #130064;
  }
  .sticky-top.solidheader .header-top span.dayntime {
    color: #263238;
  }
  .sticky-top.solidheader img.calender-icon {
    filter: brightness(0.5);
  }
  .sticky-top.solidheader img.call-icon {
    filter: brightness(0.5);
  }
  .sticky-top.solidheader .shadowonscroll {
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgb(0 0 0 / 25%);
    z-index: 99999999;
  }
  .notificationss {
    z-index: -1;
    position: relative;
  }

  /*GET A QUOTE*/
  .quotehome .nav-link.active {
    color: #130064 !important;
    font-weight: 700;
  }
  button.full-width {
    width: 100%;
  }
  .quotehome.nav-tabs .nav-link.active {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .quotehome.nav-tabs .nav-link {
    border: 1px solid #e8e2d3 !important;
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
    color: #130064;
    background: #e8e2d3;
  }
  .quotehome.nav-tabs {
    border-bottom: 1px solid #e3e0d1;
    padding-left: 30px;
    margin-top: 2px;
  }
  .form-floating > label {
    font-size: 14px !important; 
  }
  .card.quotecard {
    border-radius: 0.4rem !important;
    background: rgb(255 255 255 / 75%) !important;
  }

  .country-search .quotecontain .card.quotecard{
    background-color: #fff !important;
  }

  .quotecard {
    box-shadow: 0px 4px 24px 0px rgb(8 133 152 / 16%);
  }
  p.smalltext {
    font-size: 12px;
    line-height: 20px;
    color: #001657 !important;
    font-weight: 500;
  }
  small.smalltext {
    font-size: 12px;
    line-height: 20px;
    color: #001657 !important;
    font-weight: 500;
  }
  .lightcbg {
    background: #e8e2d3;
    padding: 10px;
    border-radius: 8px;
  }
  form.quoteform label {
    font-size: 14px;
    color: #808081;
  }
  input.form-control::placeholder {
    color: #b1b8bd;
    font-size: 14px;
  }
  select.form-select,
  input.form-control {
    height: 48px;
    border: 1px solid #d9d9d9 !important;
  }
  p.promocode {
    font-size: 12px;
    line-height: 14px;
  }
  .memberselect p.subtext {
    font-weight: 700;
  }
  .disclaimer {
    display: block !important;
  }
  .tab-content .lightcbg.rounded {
    display: flex;
    align-content: center;
    align-items: center !important;
    justify-content: flex-start;
  }
  /*NEW STYLE SELECT*/
  .select-wrap {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 0px;
    padding: 0px;
    width: auto;
    height: 58px;
    background-color: #fff;
  }

  .select-wrap label {
    font-size: 11px;
    color: #999;
    padding: 6px 8px 0;
  }

  .form-select {
    background-image: url(../../icons/raa/select-icon.png);
    background-size: 20px 20px;
  }

  .select-wrap select.form-select {
    height: auto;
    border: none !important;
    padding: 0px 10px !important;
  }

  .select-wrap:focus-within {
    border: 1px solid #130064;
  }

  /*END*/
  span.select2-selection.select2-selection--multiple {
    min-height: 48px;
    border: 1px solid #d9d9d9 !important;
  }
  label.btn.btn-lg {
    font-size: 18px;
  }
  p.quotereq {
    display: block;
    right: 30px;
    position: absolute;
    font-size: 12px;
    padding-top: 20px;
    color: #808080;
  }
  img.tooltipc {
    width: 20px;
    margin-top: -3px;
  }
  img.tooltipc.multitripss {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .lightcbg.rounded img {
    margin-left: 5px;
    margin-right: 5px;
    width: 20px;
    margin-top: -3px;
  }
  /* .select2-container--default .select2-selection--multiple .select2-selection__rendered{
	margin-top:18px!important;
} */
  /*
.select2:focus ~ label,.select2:active ~ label,.select2:focus-within ~ label,select2:focus-visible ~ label {
    transform:scale(0.55) translateY(-0.5rem) translateX(0.15rem)!important;
}
*/
  select.form-select.adjustheight {
    height: 58px;
  }
  .form-select:invalid {
    font-size: 13px !important;
  }
  // .form-select:valid {
  //   font-weight: 700 !important;
  // }
  .relative {
    position: relative;
  }

  .memberyesno {
    position: absolute;
    top: -60px;
  }

  .relative .btn {
    height: 60px !important;
    line-height: 40px !important;
    width: 48% !important;
    max-width: 80px;
  }
  .datepicker.datepicker-dropdown {
    font-size: 14px;
    padding: 2rem 1rem;
    border: 1px solid #ddd;
    font-family: 'Noto Sans' !important;
    box-shadow: 0px 0px 6px 0px #ddd;
  }

  .datepicker table tr td.active.active,
  .datepicker table tr td.active.disabled,
  .datepicker table tr td.active.disabled.active,
  .datepicker table tr td.active.disabled.disabled,
  .datepicker table tr td.active.disabled:active,
  .datepicker table tr td.active.disabled:hover,
  .datepicker table tr td.active.disabled:hover.active,
  .datepicker table tr td.active.disabled:hover.disabled,
  .datepicker table tr td.active.disabled:hover:active,
  .datepicker table tr td.active.disabled:hover:hover,
  .datepicker table tr td.active.disabled:hover[disabled],
  .datepicker table tr td.active.disabled[disabled],
  .datepicker table tr td.active:active,
  .datepicker table tr td.active:hover,
  .datepicker table tr td.active:hover.active,
  .datepicker table tr td.active:hover.disabled,
  .datepicker table tr td.active:hover:active,
  .datepicker table tr td.active:hover:hover,
  .datepicker table tr td.active:hover[disabled],
  .datepicker table tr td.active[disabled] {
    background-color: #0067f6 !important;
    background: #0067f6 !important;
  }
  .datepicker table tr td.today,
  .datepicker table tr td.today.disabled,
  .datepicker table tr td.today.disabled:hover,
  .datepicker table tr td.today:hover {
    background-color: #c0e6ec !important;
    background-image: linear-gradient(to bottom, #c0e6ec, #c0e6ec) !important;
  }

  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background-color: #00adcc !important;
  }

  button.btn.btn-default.btn-number {
    height: 48px;
    font-size: 20px;
    color: #b1b8bd;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    background-color: lightblue;
  }
  .select2-container--default.select2-container--focus
    .select2-selection--multiple {
    border-color: #ddd;
    border-radius: 0 5px 5px 0;
  }
  .select2-container--default .select2-selection--multiple {
    border-color: #ddd;
  }
  .select2-dropdown {
    z-index: 999999;
  }

  .input-date:focus {
    border: unset;
    outline: unset;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    background-color: #e5ecff;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice__remove {
    margin-left: 5px;
    float: right;
    border: 1px solid #263238;
    border-radius: 35px;
    height: 20px;
    width: 20px;
    line-height: 19px;
    text-align: center;
    padding: 0;
    margin-top: 2px;
    color: #263238;
  }
  span.select2.select2-container.select2-container--default.select2-container--focus.select2-container--above.select2-container--open {
    width: 100% !important;
  }

  span.select2.select2-container.select2-container--default.select2-container--focus.select2-container--above {
    width: 100% !important;
  }
  span.select2.select2-container.select2-container--default {
    width: 100% !important;
  }
  li.select2-selection__choice {
    background-color: #e5ecff !important;
    border: 1px solid #263238 !important;
    font-weight: 500;
  }
  button.btn.btn-info.btn-lg.full-width.hp {
    height: 58px;
  }
  /*GET A QUOTE*/
  /* QUOTE LABEL INSIDE FIX*/
  select.form-select,
  input.form-control {
    height: 58px;
    border: 1px solid #d9d9d9 !important;
  }
  span.select2-selection.select2-selection--multiple {
    min-height: 58px;
    border: 1px solid #d9d9d9 !important;
  }
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__rendered {
    margin-top: 18px !important;
  }

  select.form-select.adjustheight {
    height: 58px;
  }
  .qbtn {
    height: 58px !important;
    font-size: 1rem !important;
  }
  @media screen and (max-width: 992px) {
    .smallmargin {
      margin-bottom: 10px;
    }
  }

  /* QUOTE LABEL INSIDE FIX*/
  .award {
    padding-top: 80px;
    padding-bottom: 80px;
    background: #edf7f9;
  }
  img.aw01 {
    height: 200px;
  }

  img.aw02 {
    height: 200px;
  }

  img.aw03 {
    height: 160px;
    margin-top: 10px;
    margin-left: 35px;
    margin-right: 29px;
  }

  img.aw04 {
    height: 170px;
    margin-top: 10px;
  }
  .awardflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
  .coverage {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  //.first {
  //  background: url(../../images/raa/international.png);
  //}
  //.second {
  //  background: url(../../images/raa/domestic.png);
  //}
  //.third {
  //  background: url(../../images/raa/wintersport.png);
  //}
  //.fourth {
  //  background: url(../../images/raa/medical.png);
  //  background-position: bottom;
  //}
  //.fifth {
  //  background: url(../../images/raa/covfifth.png);
  //  background-position: bottom;
  //}
  //.sixth {
  //  background: url(../../images/raa/covsixth.png);
  //  background-position: bottom;
  //}
  .first,
  .second,
  .third,
  .fourth,
  .fifth,
  .sixth {
    height: 410px;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }
  /* .first:, .second:, .third:{
    content:"";
    position: absolute;
    height:100%;
    width: 100%;
    background: linear-gradient(0deg, #0000004f, transparent);
    z-index: 1;
}

.fourth:, .fifth:, .sixth:{
    content:"";
    position: absolute;
    height:100%;
    width: 100%;
    background: linear-gradient(180deg, #0000004f, transparent);
    z-index: 1;
} */

  h2.cvty {
    z-index: 9;
  }
  p.whyw2c {
    color: #737882;
  }

  .whyone,
  .whytwo,
  .whythree,
  .whyfour {
    background-size: 100%;
  }
  .why p.lead {
    font-size: 22px;
    color: #003c46 !important;
  }

  //.experience {
  //  background: url(../../images/raa/experiencebg.png);
  //  background-size: 100%;
  //  background-repeat: no-repeat;
  //}
  //.covid {
  //  background: url(../../images/raa/covid.png);
  //  background-size: cover;
  //}
  img.cvbn {
    width: 44px;
    margin-bottom: 10px;
  }
  .cov-benifit {
    border: 1px solid #00adcc !important;
    border-radius: 0px 0px 30px 30px;
  }
  .cov-benifit sup {
    color: #03aecd;
  }
  .testimonials {
    background: #edf7f9;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  /*OWL CAROUSEL*/
  .owl-carousel .owl-item {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .item {
    padding: 30px 20px;
    background: #fff;
    box-shadow: 0px 2px 10px #aedde6;
    border-radius: 10px;
    margin-top: 45px;
  }
  .owl-item.active.center .item {
    transition: transform 1s;
    margin: 30px;
    transform: scale(1.2);
  }
  img.starrating {
    width: 13px !important;
    display: inline-flex;
  }
  .mbcenter p.fw-normal.mb-0 {
    font-size: 13px;
  }
  .col-5.flex {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .rattingflex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: #00adcc !important;
  }
  .owl-theme .owl-dots .owl-dot span {
    width: 15px !important;
    height: 15px !important;
    background: #97c2ca !important;
  }

  /*OWL CAROUSEL*/

  a.faqall {
    font-size: 22px;
  }
  //.accordion-button.collapsed::after {
  //  background: url('../../images/raa/closed.png') !important;
  //  width: 32px !important;
  //  height: 32px;
  //}
  //.accordion-button:not(.collapsed)::after {
  //  background: url('../../images/raa/opened.png') !important;
  //  width: 40px !important;
  //  height: 40px;
  //}
  .accordion-item:first-of-type {
    border-top-left-radius: 40px !important;
    border-top-right-radius: 40px !important;
    border: 1px solid #b1b8bd;
  }
  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 40px !important;
    border-top-right-radius: 40px !important;
    border: 0px solid #b1b8bd;
  }
  .accordion-item:first-of-type .accordion-button.collapsed {
    border-top-left-radius: 40px !important;
    border-top-right-radius: 40px !important;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
  }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
  }
  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 40px !important;
    border-bottom-left-radius: 40px !important;
  }
  .accordion {
    border-radius: 40px !important;
    border: 1px solid #b1b8bd;
  }
  .accordion-button:not(.collapsed) {
    background-color: #fff !important;
  }
  button.accordion-button {
    font-weight: 400;
  }
  .rounded-5 {
    border-radius: 1.3rem !important;
  }
  /*newsletter*/
  .container.withbg {
    /* background: url(../../newsletter.png); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  }
  section.newsletter {
    z-index: 999;
    position: relative;
    padding-bottom: 70px;
  }
  //.newsletter-left {
  //  background: url(../../images/raa/bg-newsletter-left.png);
  //  background-size: cover;
  //  background-repeat: no-repeat;
  //  background-position: center center;
  //  border-radius: 20px 0 0 20px;
  //}
  .newsletter-right {
    border-radius: 0 20px 20px 0;
  }
  .rounded-20 {
    border-radius: 20px;
  }

  /*footer*/
  section.footer {
    background: #ffe600;
    padding-top: 50px;
    z-index: 999;
  }
  ul.list-unstyled.footerlink li {
    margin-bottom: 0.75rem;
  }
  .footer-top {
    border-bottom: 1px solid rgb(0 0 0 / 20%);
  }
  ul.ft-links {
    display: flex;
    list-style: none;
    width: 100%;
  }

  ul.ft-links li {
    display: flex;
  }
  ul.ft-links li a {
    color: #130064;
    text-decoration: none;
  }
  span.smallhide {
    padding-left: 10px;
    padding-right: 10px;
    color: #130064;
  }
  .text-small {
    font-size: 13px;
    color: #000 !important;
  }
  .para p {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  .footer-bottom {
    padding-bottom: 50px;
  }
  form.quoteform:focus-visible {
    outline: none!important;
  }
  :focus-visible {
    outline: none!important;
  }

  .awardflex img {
    width: 100%;
    object-fit: contain;
    max-width: 165px;
  }

  /*XL SCREENS*/
  @media screen and (min-width: 1400px) {
    .card-body div#nav-tabContent,
    .card-body .d-flex.justify-content-between.mobpad {
      padding-top: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  /*ADJUSTMENT*/
  @media screen and (min-width: 1200px) {
    .ageone,
    .agetwo {
      max-width: 115px;
    }

    .dependent {
      max-width: 150px;
    }

    .stateselection {
      max-width: 100px;
    }
  }
  @media screen and (min-width: 1450px) {
    .ageone,
    .agetwo {
      max-width: 150px;
    }

    .dependent {
      max-width: 170px;
    }

    .stateselection {
      max-width: 130px;
    }
  }
  @media screen and (max-width: 1400px) {
    h5.rev-title {
      font-size: 1rem !important;
    }
    p.rev-body {
      font-size: 0.8rem;
      line-height: 1.2rem;
    }
    .card-body div#nav-tabContent,
    .card-body .d-flex.justify-content-between.mobpad {
      padding-top: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  /* Tab Version Modify - some case */
  @media screen and (max-width: 1025px) and (min-width: 992px) {
    section.mainhero {
      margin-top: 94px;
    }
    .herocontent {
      height: 120px;
    }
    .herooverlay {
      background: linear-gradient(180deg, rgb(0 0 0 / 52%), transparent);
      height: 120px;
    }
  }
  /* Tab Version Modify - some case */
  @media screen and (max-width: 800px) and (min-width: 701px) {
    section.mainhero {
      margin-top: 94px;
    }
    .herocontent {
      height: 160px;
    }
    .herooverlay {
      background: linear-gradient(180deg, rgb(0 0 0 / 52%), transparent);
      height: 120px;
    }
    .tabs {
      margin-top: 50px;
    }
    .tabs .memberyesno {
      top: -35px;
      line-height: 20px;
    }
  }
  @media screen and (max-width: 991px) and (min-width: 801px) {
    section.mainhero {
      margin-top: 94px;
    }
    .herocontent {
      height: 180px;
    }
    .herooverlay {
      background: linear-gradient(180deg, rgb(0 0 0 / 52%), transparent);
      height: 120px;
    }
    .tabs .memberyesno {
      top: -50px !important;
      line-height: 20px !important;
    }
    .tabs {
      margin-top: 50px !important;
    }
  }
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    .tabs {
      margin-top: 50px !important;
    }
    .tabs .memberyesno {
      top: -35px !important;
      line-height: 20px !important;
    }

    .customnotice {
      font-size: 12px;
    }
    section.mainhero {
      margin-top: 130px;
    }
    .herocontent {
      height: 120px;
    }
    .herooverlay {
      background: linear-gradient(180deg, rgb(0 0 0 / 52%), transparent);
      height: 120px;
    }
  }
  @media screen and (max-width: 992px) and (min-width: 768px) {
    .customnotice {
      font-size: 12px;
    }
    .experience {
      background-color: #0067f6 !important;
    }
    .covid {
      border-radius: 35px 35px 0 0;
    }
    //.container.withbg {
    //  background: url(../../images/raa/newsmob.png);
    //  background-repeat: no-repeat;
    //  background-size: cover;
    //  border-radius: 20px;
    //}
    .btnlightfull {
      background-color: #fff !important;
      color: #000;
    }
    .btnlightfull:hover {
      color: #0067f6 !important;
    }
    .awardflex img {
      max-width: 140px;
      margin: 20px;
    }
    .newsletter-right {
      border-radius: 20px;
      background: linear-gradient(45deg, #fff, transparent);
      box-shadow: 0px -2px 8px rgb(0 173 204 / 25%);
    }
    .card-body div#nav-tabContent,
    .card-body .d-flex.justify-content-between.mobpad {
      padding-top: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  /*ALL PHONES*/
  @media screen and (max-width: 768px) {
    .d-sm-none,
    span.smallhide {
      display: none !important;
    }
    ul.ft-links {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      width: 100%;
    }

    ul.ft-links li {
      margin-bottom: 15px;
    }
  }
  @media screen and (max-width: 768px) {
    .card-body div#nav-tabContent,
    .card-body .d-flex.justify-content-between.mobpad {
      padding-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    img.warringicon {
      margin-right: 0px;
    }
    .awardflex img {
      max-width: 140px;
      margin: 20px;
    }
    /*quote*/
    span.select2.select2-container.select2-container--default.select2-container--focus.select2-container--above.select2-container--open {
      width: 100% !important;
    }

    span.select2.select2-container.select2-container--default.select2-container--focus.select2-container--above {
      width: 100% !important;
    }
    span.select2.select2-container.select2-container--default {
      width: 100% !important;
    }
    .mobpad {
      padding: 30px 17px 0px 17px !important;
    }
    p.smalltext {
      font-size: 11px;
      line-height: 16px;
    }
    form.quoteform.multi-trip.mobpad p.smalltext span {
      width: 92% !important;
    }
    form.quoteform .GoingOnCruise {
      width: 41%;
    }
    form.quoteform .GoingOnSki {
      width: 41%;
    }
    .cski {
      border-radius: 7px;
    }
    .card.quotecard {
      border-radius: 0.4rem !important;
    }
    p.quotereq {
      top: 0px;
    }
    .quotehome.nav-tabs .nav-link {
      width: 50%;
    }
    .quotehome.nav-tabs {
      border-bottom: 0px solid #0067f6;
      padding-left: 0px;
    }
    .awardflex {
      display: inherit;
      text-align: center;
    }

    .mb-sml {
      margin-bottom: 3rem;
    }
    .p-sml {
      padding: 1rem;
    }

    .first,
    .second,
    .third,
    .fourth,
    .fifth,
    .sixth {
      height: 280px;
    }

    .row.justify-content-center.border.rounded-5.border-info.mobnoboder {
      border: 0px solid #fff !important;
      padding: 20px;
    }

    .mobwhyborder {
      border: 1px solid #0067f6 !important;
      margin-bottom: 20px;
      border-radius: 20px;
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .owl-nav {
      display: none;
    }
    img.whyicon {
      width: 50px;
    }
    //.experience {
    //  background-image: url(../../experiencebgmob.png) !important;
    //  background-size: 100%;
    //  background-color: #0067f6 !important;
    //}
    .experience {
      width: 95% !important;
      border-radius: 20px;
      text-align: center;
    }
    //.covid {
    //  background: url(../../covmob.jpg) !important;
    //  width: 95% !important;
    //  border-radius: 20px 20px 0px 0px;
    //  background-position: top right;
    //  background-size: cover !important;
    //}
    .cov-benifit {
      width: 95% !important;
      margin: 0px auto !important;
      border-radius: 0px 0px 20px 20px;
    }
    img.cvbn {
      width: 60px;
    }
    .cov-benifit .col-md-4 {
      margin-bottom: 25px;
    }
    .row.align-items-end.mbcenter.mb-3 {
      align-items: center !important;
    }
    .container.withbg {
      background: none;
    }
    .accordion-button,
    .accordion-button:not(.collapsed),
    .accordion-item {
      background-color: #f6f6f6 !important;
    }

    .btnlightfull {
      width: 100% !important;
      background: #fff !important;
    }
    .item {
      margin-top: 30px;
    }
    .owl-item.active.center .item {
      -webkit-transform: scale(1);
      transform: scale(1);
      margin: 20px;
    }
    .owl-item.active.center .item .rev-title,
    .owl-item.active.center .item .rev-body {
      text-align: center;
    }
    .owl-carousel .owl-item img {
      max-width: 120px;
    }
    .mbextamargin {
      margin: 30px 0px;
    }
    section.mainhero {
      margin-top: 100px;
    }
    .herocontent {
      height: 140px;
    }
    .herooverlay {
      background: linear-gradient(180deg, rgb(0 0 0 / 52%), transparent);
      height: 120px;
    }
    .newsletter-right {
      border-radius: 20px;
      background: linear-gradient(45deg, #fff, transparent);
      box-shadow: 0px -2px 8px rgb(0 173 204 / 25%);
      text-align: center;
    }

    .newsletter-right label {
      text-align: left;
      width: 100%;
    }

    /* ********** */
    /*nav*/
    .navwithbg {
      border-radius: 0px;
    }
    .customnotice.text-center.rounded-0.rounded-bottom {
      border-bottom-right-radius: 0rem !important;
      border-bottom-left-radius: 0rem !important;
    }
    .text-xs-center {
      text-align: center;
    }
    /*footer*/
    .logofooter img.img-fluid {
      max-height: 60px;
    }
    .logofooter {
      width: 100%;
      float: left;
    }
    .social {
      width: 42%;
      float: left;
    }
    .googlerev {
      width: 58%;
      float: left;
    }
    .footer-bottom p.text-light.text-small {
      color: #000 !important;
    }
    .footer-bottom p.text-light.text-small a {
      color: #333 !important;
    }
    ul.list-unstyled.footerlink li {
      margin-bottom: 25px;
      color: #ffe600;
    }
    .mbextamargin a.h5,
    .mbextamargin .h5,
    .mbextamargin p {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 400px) {
  }
  /*Large MOB fix*/
  @media screen and (max-width: 640px) {
    .herocontent {
      height: 140px;
    }

    .card.quotecard {
      border-radius: 0.4rem !important;
      background: rgb(255 255 255 / 100%);
    }
  }
  @media (max-width: 520px) {
    .return-calendar .p-calendar .p-datepicker {
     /*left: -75px !important;*/
    }
  }
  @media screen and (max-width: 400px) {
    .herocontent {
      height: 140px;
    }
    .herooverlay {
      background: linear-gradient(180deg, rgb(0 0 0 / 52%), transparent);
      height: 120px;
    }
  }
}
